<template>
    <!-- <div v-if="loading" class="bg-darkGrayBlue ptrn-vdashed w-full h-full">
        <div>warte</div>
    </div> -->
    <loader v-if="loading" :style="'box'" :title="loadingTxt"></loader>
    <div class="flexgrid-demo" ref="pageArea">
        <div v-if="!loading" @contextmenu="onImageRightClick">
            <div v-if="this.$store.getters.getPageData.editMode">
                <Button type="button" icon="pi pi-plus" title="Widget hinzufügen" @click="addNewWidget()" style="margin-right: 0.5em" />
            </div>
            
                <div v-if="pageContent !== null">
                    <div>
                        <!-- <div :class="'bg-' + pageContent.style.background + ' fg-' + pageContent.style.foreground"> -->
                        <div>
                            <div v-if="isEdit">
                                <!-- <Button type="button" icon="pi pi-plus" title="Widget hinzufügen" @click="addWidget(null)" style="margin-right: 0.5em" /> -->
                                <h5>{{ pageContent.label }}</h5>
                                <h5 v-if="pageContent.type === 'grid'">{{ pageContent.type }}</h5>
                            </div>
                            <!-- <component :is="componentKey" :valueObj="paramList"></component> -->

                            <div v-if="pageContent.type === 'special'">
                                <div class="flexgrid-demo">
                                    <grid-layout
                                        v-model:layout="pageContent.components"
                                        :col-num="12"
                                        :row-height="30"
                                        :is-mirrored="false"
                                        :vertical-compact="true"
                                        :margin="[12, 12]"
                                        :use-css-transforms="true"
                                        :responsive="true"
                                        :show-grid-lines="true"
                                        @layout-created="layoutCreatedEvent"
                                        @layout-before-mount="layoutBeforeMountEvent"
                                        @layout-mounted="layoutMountedEvent"
                                        @layout-ready="layoutReadyEvent"
                                        @layout-updated="layoutUpdatedEvent"
                                    >
                                    <template #default="{ gridItemProps }">
                                        <grid-item
                                            v-for="item in pageContent.components"
                                            v-bind="gridItemProps"
                                            :x="item.x"
                                            :y="item.y"
                                            :w="item.w"
                                            :h="item.h"
                                            :i="item.i"
                                            :key="item.i"
                                            :is-draggable="editMode"
                                            :is-resizable="editMode"
                                            :border-radius-px="10"
                                            class=""
                                            :show-close-button="false"
                                            :use-border-radius="true"
                                            :resizeOption="{edges: { top: false, left: true, bottom: true, right: true }}"
                                            drag-allow-from=".handle"
                                            drag-ignore-from=".no-drag"
                                            @resize="resizeEvent"
                                            @move="moveEvent"
                                            @resized="resizedEvent"
                                            @container-resized="containerResizedEvent"
                                            @moved="movedEvent"
                                        >
                                            <component
                                                :is="item.component"
                                                :createdLayout="createdLayout"
                                                :readyLayout="readyLayout"
                                                :content="item"
                                                :dragObj="item"
                                                :valueObj="item.data"
                                                :dataObj="item.data"
                                                :styleObj="item.style"
                                                :key="item"
                                                @entrySave="
                                                    ($value) => {
                                                        changeVal($value);
                                                    }
                                                "
                                                @entryDuplicate="
                                                    ($value) => {
                                                        duplicateVal($value);
                                                    }
                                                "
                                                @entryDelete="
                                                    ($value) => {
                                                        deleteVal($value);
                                                    }
                                                "
                                            ></component>
                                        </grid-item>
                                        </template>
                                    </grid-layout>
                                </div>
                            </div>

                            <div v-if="pageContent.type === 'grid'">
                                <div class="flexgrid-demo">
                                    <grid-layout
                                        v-model:layout="pageContent.components"
                                        :col-num="12"
                                        :row-height="30"
                                        :is-mirrored="false"
                                        :vertical-compact="true"
                                        :margin="[12, 12]"
                                        :use-css-transforms="true"
                                        :responsive="true"
                                        :show-grid-lines="true"
                                        @layout-created="layoutCreatedEvent"
                                        @layout-before-mount="layoutBeforeMountEvent"
                                        @layout-mounted="layoutMountedEvent"
                                        @layout-ready="layoutReadyEvent"
                                        @layout-updated="layoutUpdatedEvent"
                                    >
                                    <template #default="{ gridItemProps }">
                                        <grid-item
                                            v-for="item in pageContent.components"
                                            v-bind="gridItemProps"
                                            :x="item.x"
                                            :y="item.y"
                                            :w="item.w"
                                            :h="item.h"
                                            :i="item.i"
                                            :key="item.i"
                                            :is-draggable="editMode"
                                            :is-resizable="editMode"
                                            :border-radius-px="10"
                                            class=""
                                            :show-close-button="false"
                                            :use-border-radius="true"
                                            :resizeOption="{edges: { top: false, left: true, bottom: true, right: true }}"
                                            drag-allow-from=".handle"
                                            drag-ignore-from=".no-drag"
                                            @resize="resizeEvent"
                                            @move="moveEvent"
                                            @resized="resizedEvent"
                                            @container-resized="containerResizedEvent"
                                            @moved="movedEvent"
                                        >
                                            <component
                                                :is="item.component"
                                                :createdLayout="createdLayout"
                                                :readyLayout="readyLayout"
                                                :content="item"
                                                :dragObj="item"
                                                :valueObj="item.data"
                                                :dataObj="item.data"
                                                :styleObj="item.style"
                                                :key="item"
                                                @entrySave="
                                                    ($value) => {
                                                        changeVal($value);
                                                    }
                                                "
                                                @entryDuplicate="
                                                    ($value) => {
                                                        duplicateVal($value);
                                                    }
                                                "
                                                @entryDelete="
                                                    ($value) => {
                                                        deleteVal($value);
                                                    }
                                                "
                                            ></component>
                                        </grid-item>
                                        </template>
                                    </grid-layout>
                                </div>
                            </div>

                            <div v-else-if="pageContent.type === 'boxes'" class="grid">
                                <div class="col-12 lg:col-6 md:col-6 xl:col-4 border-solid border-round bg-black">
                                <Draggable
                                    v-model="pageContent.components"
                                    item-key="i"
                                    handle=".handle"
                                    tag="transition-group"
                                    v-bind="dragOptions"
                                    :component-data="{
                                        tag: 'div',
                                        type: 'transition-group',
                                        name: !drag ? 'flip-list' : null,
                                    }"
                                    class="grid"
                                    style="margin-top: 0.5em"
                                    @start="drag = true"
                                    @end="dragOff"
                                >
                                    <template #item="{ element }">
                                        <div :key="element.i" :class="'col-12 xll:col-' + element.width">
                                            <!-- <div v-for="col in element" :key="col.id" class="p-col"> -->
                                            <component
                                                :is="element.component"
                                                :createdLayout="createdLayout"
                                                :readyLayout="readyLayout"
                                                :content="element"
                                                :dragObj="element"
                                                :valueObj="element.data"
                                                :dataObj="element.data"
                                                :styleObj="element.style"
                                                :key="element.i"
                                                @entrySave="
                                                    ($value) => {
                                                        changeVal($value);
                                                    }
                                                "
                                                @entryDuplicate="
                                                    ($value) => {
                                                        duplicateVal($value);
                                                    }
                                                "
                                                @entryDelete="
                                                    ($value) => {
                                                        deleteVal($value);
                                                    }
                                                "
                                            ></component>
                                            <!-- </div> -->
                                            <!-- <dashboardPanelValues :dragObj="element" :valueObj="paramList2"></dashboardPanelValues> -->
                                        </div>
                                    </template>
                                </Draggable>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-4 border-solid">
                                    <Draggable
                                    v-model="pageContent.components"
                                    item-key="i"
                                    handle=".handle"
                                    tag="transition-group"
                                    v-bind="dragOptions"
                                    :component-data="{
                                        tag: 'div',
                                        type: 'transition-group',
                                        name: !drag ? 'flip-list' : null,
                                    }"
                                    class="grid"
                                    style="margin-top: 0.5em"
                                    @start="drag = true"
                                    @end="dragOff"
                                >
                                    <template #item="{ element }">
                                        <div :key="element.i" :class="'col-12 xl:col-' + element.width">
                                            <!-- <div v-for="col in element" :key="col.id" class="p-col"> -->
                                            <component
                                                :is="element.component"
                                                :createdLayout="createdLayout"
                                                :readyLayout="readyLayout"
                                                :content="element"
                                                :dragObj="element"
                                                :valueObj="element.data"
                                                :dataObj="element.data"
                                                :styleObj="element.style"
                                                :key="element.i"
                                                @entrySave="
                                                    ($value) => {
                                                        changeVal($value);
                                                    }
                                                "
                                                @entryDuplicate="
                                                    ($value) => {
                                                        duplicateVal($value);
                                                    }
                                                "
                                                @entryDelete="
                                                    ($value) => {
                                                        deleteVal($value);
                                                    }
                                                "
                                            ></component>
                                            <!-- </div> -->
                                            <!-- <dashboardPanelValues :dragObj="element" :valueObj="paramList2"></dashboardPanelValues> -->
                                        </div>
                                    </template>
                                </Draggable>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                    <!-- <div v-if="false">
                        <Chart ref="primeChart" type="line" :data="basicData" :options="basicOptions" />
                    </div> -->

                    <!-- <p>
                        ROUTE:
                        {{ this.siteName + ' ' + $route.params.param + ' ' + appContent.toString() + ' ' + page }}
                    </p> -->
                </div>

        </div>
    </div>
</template>

<script>
const components = [];

import { defineComponent } from 'vue';
import store from '@/store';
import EventBus from '../event-bus';
import { mongoResponseToast } from '../helpers';
import { GridItem, GridLayout } from 'vue-ts-responsive-grid-layout';
import { mapGetters, mapActions } from 'vuex'
import SocketioService from '@/services/socketioService.js';
import { addEventListener, removeEventListener } from '@/services/eventListener';
import { findIndexByTag } from '../helpers';
import { uuid } from 'vue-uuid';

export default defineComponent({
    name: 'pageWidgets',
    mixins: [addEventListener, removeEventListener],
    components: {
        GridLayout,
        GridItem,
        components
    },
    setup() {
        // const primeChart = ref();
    },
    data() {
        return {
            layout: [
                { x: 0, y: 0, w: 2, h: 2, i: '1040', static: false, label: 'meister' },
                { x: 2, y: 0, w: 2, h: 4, i: '101', static: false, label: 'meister2' },
            ],
            editMode: false,
            draggable: true,
            resizable: true,
            index: 0,
            grid: null,
            count: {
                value: 0,
            },
            // primeChart: null,
            loading: true,
            createdLayout: false,
            mountedLayout: false,
            readyLayout: false,
            drag: false,
            cnt: 0,
            values: [],
            pageContent: null,
            paramList: null,
            // componentKey: "dashboardPanelValues",
        };
    },
    props: {
        // paramList: Object,
        page: {
            type: String,
            required: true,
        },
        appContent: Boolean,
        componentKey: String,
    },
    computed: {
        ...mapGetters({
            getAppLoading: 'getAppLoading',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getLoadedState: 'opcua/getLoadedState',
            isMaster: 'auth/isMaster',
            getComponent: 'types/getComponent',
        }),
        isEdit: function () {
            if (store.getters.getPageData.isEdit === true) {
                return true;
            } else {
                return false;
            }
        },
        myList: function () {
            return [
                { id: 1, value: 'Card A' },
                { id: 2, value: 'Card B' },
            ];
        },
        siteName: function () {
            return this.$route.params.page;
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            };
        },
        loadingTxt() {
            let page = "";
            if (this.pageContent && this.pageContent.label) page = this.pageContent.label;
            return `lade Module ${page}`;  
        },
    },
    watch: {
        eventLog: function () {
            // const eventsDiv = this.$refs.eventsDiv;
            // eventsDiv.scrollTop = eventsDiv.scrollHeight;
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        addWidgetEvent: function(widget) {
          console.log("WIDGET EVENT ", widget);
        },
    },
    created() {
        this.getPageData();
        this.$watch(
            () => this.$route.params,
            (toParams, previousParams) => {
                console.log("APP_PAGE PREV:", previousParams, "TO:", toParams)
                // this.getPageData();
                this.$store.dispatch('setPath', { name: this.pageContent.name, label: this.pageContent.label });
            }
        );
        // this.getPageData();
        // store.dispatch("logout");
    },
    methods: {
        ...mapActions({
            loadModules: 'opcua/loadModules',
            loadComponent: 'types/loadComponent',
        }),
        itemTitle(item) {
            let result = item.i;
            if (item.static) {
                result += ' - Static';
            }
            return result;
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode === true) {
                this.editMode = true;
            } else {
                this.editMode = false;
            }
        },
        moveEvent: function (i, newX, newY) {
            const msg = 'MOVE i=' + i + ', X=' + newX + ', Y=' + newY;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        movedEvent: function (i, newX, newY) {
            const msg = 'MOVED i=' + i + ', X=' + newX + ', Y=' + newY;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        resizeEvent: function (i, newH, newW, newHPx, newWPx) {
            const msg = 'RESIZE i=' + i + ', H=' + newH + ', W=' + newW + ', H(px)=' + newHPx + ', W(px)=' + newWPx;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        resizedEvent: function (i, newX, newY, newHPx, newWPx) {
            const msg = 'RESIZED i=' + i + ', X=' + newX + ', Y=' + newY + ', H(px)=' + newHPx + ', W(px)=' + newWPx;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        containerResizedEvent: function (i, newH, newW, newHPx, newWPx) {
            const msg = 'CONTAINER RESIZED i=' + i + ', H=' + newH + ', W=' + newW + ', H(px)=' + newHPx + ', W(px)=' + newWPx;
            // this.eventLog.push(msg);
            if (this.editMode) {
                console.log(msg);
            }
        },
        layoutCreatedEvent: function (newLayout) {
            // this.eventLog.push("Created layout");\
            if (this.editMode) {
                console.log('Created layout: ', newLayout);
            }
            this.createdLayout = true;
        },
        layoutBeforeMountEvent: function (newLayout) {
            // this.eventLog.push("beforeMount layout");
            if (this.editMode) {
                console.log('beforeMount layout: ', newLayout);
            }
        },
        layoutMountedEvent: function (newLayout) {
            // this.eventLog.push("Mounted layout");
            if (this.editMode) {
                console.log('Mounted layout: ', newLayout);
            }
            this.mountedLayout = true;
        },
        layoutReadyEvent: function (newLayout) {
            // this.eventLog.push("Ready layout");
            if (this.editMode) {
                console.log('Ready layout: ', newLayout);
            }
            this.readyLayout = true;
        },
        layoutUpdatedEvent: function (newLayout) {
            // this.eventLog.push("Updated layout");
            if (this.editMode) {
                console.log('Updated layout: ', newLayout);
            }
            // console.log("PageContent layout: ", this.pageContent)
            // this.changeVal(null);
        },
        logger(evt) {
            console.log(evt);
        },
        addNewWidget() {
            const node = {
                x: Math.round(12 * Math.random()),
                y: Math.round(5 * Math.random()),
                w: Math.round(1 + 3 * Math.random()),
                h: Math.round(1 + 3 * Math.random()),
            };
            this.items.value.push(node);
        },
        addData() {
            // // const chart = this.$refs.primeChart.value.chart;
            // const chart = this.$refs.primeChart.chart;
            // let rndm = getRandomInt(0, 100);
            // // chart.data.datasets[0].data.push(9);
            // console.log(chart.data.datasets[0].data);
            // this.basicData.datasets[0].data[4] = rndm;
            // // chart.data.labels.push(/* NEW DATA LABEL */)
            // // chart.data.datasets[0].data.push(/* NEW DATA VALUE */)
            // // chart.update();
        },
        // getComponent(fileName) {
        //     // return () => import(`./widgets/${fileName}.vue`).then(module => module.default || module);
        //     // defineAsyncComponent(() => import(`./widgets/${fileName}.vue`));
            
        //     import(`./widgets/${fileName}.vue`)
        //     .then(module => {
        //         console.log(`[DYN-IMPORT] ./widgets/${fileName}.vue`, module.default);
        //         console.log(module.default.name);
        //         this.$options.components[module.default.name] = module.default;
        //         this.loading = false;
        //         console.log('loading is false');
        //         return module.default.name;
        //     })
        //     .catch(err => {
        //         console.error(`[DYN-IMPORT ERR] ./widgets/${fileName}.vue`, err.msg);
        //     });
        // },
        getPageData() {
            this.loading = true;
            if (this.siteName !== null) {
                console.log(this.siteName);
                SocketioService.getViewPageEntry(this.siteName, (err, response) => {
                    if (!err && response !== null) {
                        this.pageContent = response;
                        // this.loading = false;
                        if (this.pageContent.type === 'grid' || this.pageContent.type === 'boxes' || this.pageContent.type === 'special') {
                            // store.dispatch('setPageData', { editMode: true });
                            store.dispatch('setPageData', { name: this.pageContent.name, label: this.pageContent.label });
                            this.getPageComponents();
                        }
                    }
                });
            }
        },
        // async getPageComponents() {
        //     this.pageContent.components.forEach((item) => {
        //         // this.getComponent(item.component);
        //         this.loadComponent(item.component)
        //         .then(component => {
        //             this.$options.components[component.name] = component;
        //             this.loading = false;
        //         })
        //         .catch(err => {
        //             console.error('Error at loadComponent:', err.msg);
        //         });
        //     });
        // },
        async getPageComponents() {
            const loadPromises = this.pageContent.components.map(async (item) => {
                // undefined=not loaded, null=in loading queue
                // if (this.getComponent(item.component) === undefined) {
                if (!this.getComponent(item.component)) {
                    try {
                        await this.loadComponent(item.component);
                        // const component = await this.loadComponent(item.component);
                        // this.$options.components[component.name] = component;
                    } catch (err) {
                        console.error('Error at loadComponent:', err.message);
                    }
                }
            });
            await Promise.all(loadPromises);
            this.loading = false;
        },
        getParameterStatusIO() {
            // this.loading = true;
            SocketioService.getStatusIO((response) => {
                this.paramList = response;
                // this.loading = false;
            });
        },
        addColumn() {
            let index, index2;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.grids.length;
            this.grids[index] = [];
            index2 = this.grids[index].length;
            console.log(index2);
            this.grids[index][index2] = {};
            this.grids[index][index2].id = index + ':' + index2;
            this.grids[index][index2].name = 'GUSCH';
            console.log(this.grids);
        },
        removeColumn() {
            this.grids.pop();
        },
        generateId() {
            // let rndm = getRandomInt(0, 65535);
            const rndm = uuid.v4();
            const index = findIndexByTag(this.pageContent.components, 'i', rndm);
            if (index === -1) {
                return rndm;
            } else {
                // this.generateId();
                return index;
            }
        },
        duplicateWidget(settings) {
            let index;
            let widget;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.pageContent.components.length;
            console.log(index);
            // this.pageContent.components[index] = [];
            console.log(settings);
            if (settings !== null) {
                widget = { ...settings };
                widget.y = widget.y + 2;
                // widget.id = index;
                const rndm = this.generateId();
                widget.i = rndm;
            }
            this.changeVal(widget);
        },
        addWidget(settings) {
            let index;
            let widget;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.pageContent.components.length;
            console.log(index);
            // this.pageContent.components[index] = [];
            console.log(settings);
            if (settings !== null) {
                let data = null;
                if (settings.isArray) {
                    data = []
                } else {
                    data = {}
                }
            const rndm = this.generateId();
              const lastWidget = {...this.pageContent.components[index-1]};
                widget = {
                    // id: index,
                    // id: getRandomInt(0, 65535),
                    // i: getRandomInt(0, 65535),
                    i: rndm,
                    name: 'Neues Widget',
                    label: 'Neues Widget',
                    component: settings.component,
                    width: 2,
                    style: {
                        background: 'darkBlue',
                        foreground: 'white',
                    },
                    data: data,
                    static: false,
                    moved: false,
                    y: lastWidget.y + 2,
                    x: lastWidget.x,
                    h: 2,
                    w: 2,
                };
            }
            // this.pageContent.components.push(widget);
            this.changeVal(widget);
            // console.log(this.pageContent.components);

            // index2 = this.pageContent.components[index].length;
            // console.log(index2);
            // this.pageContent.components[index][index2] = {};
            // this.pageContent.components[index][index2].id = index + ":" + index2;
            // this.pageContent.components[index][index2].name = "GUSCH";
            // console.log(this.grids);
        },
        dragOff(event) {
            console.log(event);
            this.changeVal(null);
            this.drag = false;
            // console.log(this.grids3);
        },
        getIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.id === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        getGridIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.i === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        savePage(callback) {
            SocketioService.setViewPage(this.pageContent, (err, response, data) => {
                if (err || response === null) {
                    callback(false);
                } else {
                    // this.loading = false;
                    const result = mongoResponseToast(response, this.$root, data);
                    if (result) callback(true);
                }
            });
        },
        changeVal(entry) {
            console.log("[PAGE] CHANGEVAL:", entry);
            if (entry !== null) {
                // const index = this.getIndex(entry.id);
                const index = this.getGridIndex(entry.i);
                console.log('[PAGE] widget index/id: ' + index);
                if (index === -1) {
                    this.pageContent.components.push(entry);
                } else {
                    this.pageContent.components[index] = entry;
                }
            }
        },
        duplicateVal(entry) {
            this.duplicateWidget(entry);
        },
        deleteVal(entry) {
            const index = this.getGridIndex(entry.i);
            this.pageContent.components.splice(index, 1);
        },
        onNodeSelect(node) {
            this.$toast.add({
                severity: 'success',
                summary: 'Node Selected',
                detail: node,
                life: 3000,
            });
        },
        getParameterStatusIOTree() {
            //store.dispatch('getIoTree', null);
        },
        evntFnctAddWidget(widget) {
            console.log('[WIDGET] add:', widget);
            this.addWidget(widget);
        },
        evntFnctSavePage(callback) {
            this.savePage(result => {
                console.log("[PAGE] pageWidgets saved:", result);
                callback(result);
            });
        },
    },
    mounted() {
        // EventBus.on('addWidget', (widget) => {
        //   console.log('[WIDGET] add:', widget);
        //   this.addWidget(widget);
        // });
        // EventBus.on('savePage', (callback) => {
        //   this.savePage(result => {
        //       console.log("[PAGE] pageWidgets saved:", result);
        //       callback(result);
        //   });
        // });
        EventBus.on('addWidget', this.evntFnctAddWidget);
        EventBus.on('savePage', this.evntFnctSavePage);
        // this.$router.push({ path: this.siteName });
        // this.getPageData();
        this.getParameterStatusIOTree();
    },
    // DEBUG
    beforeUnmount() {
        EventBus.off('addWidget', this.evntFnctAddWidget);
        EventBus.off('savePage', this.evntFnctSavePage);
        // EventBus.off('addWidget');
        // EventBus.off('savePage');

    },
});
</script>

<style lang="scss" scoped>
.cell {
    background-color: lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.9;
    background: #ffd000;
    border-radius: 10px;
}

.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

.flexgrid-demo {
    background-color: var(--surface-b);
    color: var(--text-color);

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 400px);
        grid-template-rows: repeat(3, 200px);
        grid-gap: 0.2em;
    }

    .grid-move {
        transition: all 0.3s;
    }

    .box {
        background-color: var(--surface-e);
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        height: 100%;
        overflow: hidden;
    }

    .box-stretched {
        height: 100%;
    }

    .vertical-container {
        margin: 0;
        height: 200px;
        background: var(--surface-d);
        border-radius: 4px;
    }

    .nested-grid .p-col-4 {
        padding-bottom: 1rem;
    }

    .dynamic-box-enter-active,
    .dynamic-box-leave-active {
        transition: all 0.5s;
    }

    .dynamic-box-enter,
    .dynamic-box-leave-to {
        opacity: 0;
    }

    .dynamic-box-enter,
    .dynamic-box-leave-to {
        transform: translateX(30px);
    }

    p {
        margin: 0;
        line-height: 1.5;
    }

    .slideLoad-enter,
    .slideLoad-leave-to {
        opacity: 0;
        transform: translateX(50%);
    }

    .slideLoad-leave-active {
        position: absolute;
    }

    .content p,
    .content h2 {
        font-family: 'SensorFont' !important;
    }

    .p-panel-content {
        line-height: 0px !important;
        min-height: 0% !important;
    }
}

.content {
    height: 100%;
    width: 100%;
    border: 2px solid #42b983;
    border-radius: 5px;
}

.vue-grid-layout {
    // background: #eee;
    // border: 0px solid #fff;
    position: relative;
    transition: height 2000ms ease;
}
$grid-line-color: #000;
$grid-item-bg-color: #720c0c;
$grid-item-border-radius: 8px;
$grid-item-text-color: white;
$grid-item-font-size: 1rem;
$grid-item-static-bg-color: #393d42;
$grid-item-placeholder-bg-color: red;
$grid-item-placeholder-opacity: .5;


#rowHeight {
  max-width: 70px !important;
}
#colNum {
  max-width: 70px !important;
}
#mlr {
  max-width: 70px !important;
}
#mtb {
  max-width: 70px !important;
}
#maxRows {
  max-width: 70px !important;
}
#borderRadius {
  max-width: 70px !important;
}
.container {
  background: #646cff;
  min-width: 330px;
}
.vue-grid-item .text {
  bottom: 0;
  font-size: 24px;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 0px){
    .p-xs-1 {
        width: 8.3333%;
    }
    .p-xs-2 {
        width: 16.6667%;
    }
    .p-xs-3 {
        width: 25%;
    }
    .p-xs-4 {
        width: 33.3333%;
    }
    .p-xs-5 {
        width: 41.6667%;
    }
    .p-xs-6 {
        width: 50%;
    }
    .p-xs-7 {
        width: 58.3333%;
    }
    .p-xs-8 {
        width: 66.6667%;
    }
    .p-xs-9 {
        width: 75%;
    }
    .p-xs-10 {
        width: 83.3333%;
    }
    .p-xs-11 {
        width: 91.6667%;
    }
    .p-xs-12 {
        width: 100%;
    }
}

@media screen and (min-width: 576px){
    .p-sm-1 {
        width: 8.3333%;
    }
    .p-sm-2 {
        width: 16.6667%;
    }
    .p-sm-3 {
        width: 25%;
    }
    .p-sm-4 {
        width: 33.3333%;
    }
    .p-sm-5 {
        width: 41.6667%;
    }
    .p-sm-6 {
        width: 50%;
    }
    .p-sm-7 {
        width: 58.3333%;
    }
    .p-sm-8 {
        width: 66.6667%;
    }
    .p-sm-9 {
        width: 75%;
    }
    .p-sm-10 {
        width: 83.3333%;
    }
    .p-sm-11 {
        width: 91.6667%;
    }
    .p-sm-12 {
        width: 100%;
    }
}


// @media screen and (min-width: 992px){
@media screen and (min-width: 1400px){
    .md-1 {
        width: 8.3333%;
    }
    .md-2 {
        width: 16.6667%;
    }
    .md-3 {
        width: 25%;
    }
    .md-4 {
        width: 33.3333%;
    }
    .md-5 {
        width: 41.6667%;
    }
    .md-6 {
        width: 50%;
    }
    .md-7 {
        width: 58.3333%;
    }
    .md-8 {
        width: 66.6667%;
    }
    .md-9 {
        width: 75%;
    }
    .md-10 {
        width: 83.3333%;
    }
    .md-11 {
        width: 91.6667%;
    }
    .md-12 {
        width: 100%;
    }
}


@media screen and (min-width: 1200px){
    .p-lg-1 {
        width: 8.3333%;
    }
    .p-lg-2 {
        width: 16.6667%;
    }
    .p-lg-3 {
        width: 25%;
    }
    .p-lg-4 {
        width: 33.3333%;
    }
    .p-lg-5 {
        width: 41.6667%;
    }
    .p-lg-6 {
        width: 50%;
    }
    .p-lg-7 {
        width: 58.3333%;
    }
    .p-lg-8 {
        width: 66.6667%;
    }
    .p-lg-9 {
        width: 75%;
    }
    .p-lg-10 {
        width: 83.3333%;
    }
    .p-lg-11 {
        width: 91.6667%;
    }
    .p-lg-12 {
        width: 100%;
    }
}

@media screen and (min-width: 1600px){
    .p-xl-1 {
        width: 8.3333%;
    }
    .p-xl-2 {
        width: 16.6667%;
    }
    .p-xl-3 {
        width: 25%;
    }
    .p-xl-4 {
        width: 33.3333%;
    }
    .p-xl-5 {
        width: 41.6667%;
    }
    .p-xl-6 {
        width: 50%;
    }
    .p-xl-7 {
        width: 58.3333%;
    }
    .p-xl-8 {
        width: 66.6667%;
    }
    .p-xl-9 {
        width: 75%;
    }
    .p-xl-10 {
        width: 83.3333%;
    }
    .p-xl-11 {
        width: 91.6667%;
    }
    .p-xl-12 {
        width: 100%;
    }
}

@media screen and (min-width: 2000px){
    .p-xxl-1 {
        width: 8.3333%;
    }
    .p-xxl-2 {
        width: 16.6667%;
    }
    .p-xxl-3 {
        width: 25%;
    }
    .p-xxl-4 {
        width: 33.3333%;
    }
    .p-xxl-5 {
        width: 41.6667%;
    }
    .p-xxl-6 {
        width: 50%;
    }
    .p-xxl-7 {
        width: 58.3333%;
    }
    .p-xxl-8 {
        width: 66.6667%;
    }
    .p-xxl-9 {
        width: 75%;
    }
    .p-xxl-10 {
        width: 83.3333%;
    }
    .p-xxl-11 {
        width: 91.6667%;
    }
    .p-xxl-12 {
        width: 100%;
    }
}
</style>
